<template>
  <div>
    <div class="hc_detail">
      <el-container>
        <el-main class="cool_detail_box">
          <div class="cd_hedader">
            <h3 class="cd_title">{{detail.name}}</h3>
            <p class="cd_time">
              <span class="el-icon-time">{{coolMoment(detail.publishTime)}}</span>
            </p>
            <h2 class="cd_tagline" v-if="tagList.length!=0">专属标签</h2>
            <div class="cd_tagbox" v-if="tagList.length!=0">
              <div class="cd_cover">
                <img :src="setUrl(detail.showImg)" alt="">
              </div>
              <div class="cd_rate_box">
                <div class="cd_rate_item" v-for="item of tagList" :key="item.id">
                  <span class="cool_rname">{{item.tag}}:</span>
                  <el-rate
                          v-model="item.num"
                          disabled
                          void-icon-class="iconfont icon-pethome-xingxing"
                          :icon-classes="['iconfont icon-pethome-xingxing','iconfont icon-pethome-xingxing','iconfont icon-pethome-xingxing']"
                          void-color="#ccc"
                          :colors="['#049aee','#049aee','#049aee']"
                          class="pet_rate"
                  ></el-rate>
                </div>
              </div>
            </div>
            <div class="cd_banner">
              <h2>图片鉴赏: <span>点击观看原始高清图</span></h2>
              <el-carousel :interval="4000" type="card" height="300px" v-if="coolPhotos.length!=0">
                <el-carousel-item v-for="(item,index) of coolPhotos" :key="index">
                  <div class="pet_photos_item" @click="showHimg(index)">
                    <img :src="setUrl(item)">
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <h2 class="cd_tagline">正文赏析</h2>
            <div class="cd_con" v-html="coolContent">
              {{coolContent}}
            </div>
          </div>
          <div class="cd_detail_good">
            <span class="like" @click="like">点赞：{{detail.pageviews}}</span>
          </div>
          <reply-comment :rtype="sendType" :rid="sendId"></reply-comment>
        </el-main>
        <el-aside class="cd_aside">
          <el-button class="back_btn" type="primary" @click="goList">返回</el-button>
          <div class="art_detail_user">
            <img :src="userPhoto"/>
            <p>{{detail.userName}}</p>
          </div>
          <!--<div class="art_hotList">
            <h3>24小时热帖榜</h3>
            <ul>
              <li v-for="(item,index) in coolHotList" :key="item.id">
                <div>{{index+1}}</div>
                <div>
                  <p @click="toDetail(item.id)">{{item.name}}</p>
                  <p>
                    <span class="look">{{item.pageviews}}</span>
                    <span class="dis">{{item.commentCount}}</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>-->
          <div class="adlist">
            <el-carousel height="200px" indicator-position="outside">
              <el-carousel-item v-for="item in adlist" :key="item.fdCode">
                <div class="adimg">
                  <a :href="item.fdClickUrl" target="_blank">
                    <img :src="item.fdPicurl">
                  </a>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-aside>
      </el-container>
      <el-image-viewer
              v-if="isShow"
              :initial-index="index"
              :on-close="closeViewer"
              :url-list="srcList"
              class="cd_imglook"
      />
    </div>
  </div>
</template>
<script>
    import fetch from '@/services/service';
    import instance from '@/services/instance';
    import moment from 'moment';
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
    import ReplyComment from '@/components/replyComment';

    export default {
        name: "CoolDetail",
        components: { ElImageViewer,ReplyComment },
        data() {
            return {
                detail: {},
                coolPhotos:[],
                index: 0,
                isShow: false,
                srcList: [],
                adlist: [],
                coolHotList: [],
                parmid: '',
                sendType: 2,
                sendId: this.$route.params.id,
            };
        },
        watch: {
            $route() {
                this.parmid = this.$route.params.id
                this.sendId = this.$route.params.id
            },
            parmid() {
                this.getDetail()
            },
        },
        created(){
            this.getDetail();
            this.getAd();
            //this.getHotList();
        },
        computed:{
            setUrl() {
                return url=>{
                    if(url.indexOf("http")==0){
                        return url;
                    }else{
                        return '/officialWebsiteMgr/resource/file/getImage?fileName=' + url
                    }
                }
            },
            tagList(){
                if(this.detail.showTag && this.detail.showTag!=""){
                    return JSON.parse(this.detail.showTag)
                }else{
                    return [];
                }
            },
            coolMoment(){
                return time=>{
                    return moment(time).format('YY-MM-DD HH:mm:ss')
                }
            },
            coolContent(){
                if(this.detail.content){
                    let _cont = JSON.parse(this.detail.content);
                    let _html="";
                    _cont.map(item=>{
                        if(item.type=="words"){
                            _html+="<p>"+item.cont+"</p>";
                        }
                        if(item.type=="img"){
                            _html+="<p class='conimg'><img src='/officialWebsiteMgr/resource/file/getImage?fileName="+item.cont+"'></p>";
                        }
                    })
                    return _html;
                }
            },
            userPhoto(){
                if(this.detail && this.detail.userPhoto){
                    return "/officialWebsiteMgr/resource/file/getImage?fileName="+this.detail.userPhoto;
                }else{
                    return require("../../assets/img/head.png");
                }
            }

        },
        methods:{
            getDetail(){
                fetch.post("/officialWebsiteMgr/resource/show_favor/desc",{id:this.$route.params.id}).then(res=>{
                    if(res.code==0){
                        this.detail = res.data;
                        if(res.data.bannerImg && res.data.bannerImg!=""){
                            this.coolPhotos = res.data.bannerImg.split(",");
                            if(this.coolPhotos.length!=0){
                                let srcAry = this.coolPhotos.map(item=>{
                                    return this.setUrl(item)
                                })
                                this.srcList = srcAry
                            }
                        }
                    }
                })
            },
            showHimg(ind){
                this.index = ind;
                this.isShow = true;
            },
            closeViewer(){
                this.isShow = false;
            },
            getAd(){
                fetch.get("https://www.fishsea.cn/fdAdmin/api/getFd?fd_place_code=11&&fd_plat_code=8").then(res=>{
                    if(res.code==0){
                        this.adlist = res.fds;
                    }
                })
            },
            /*getHotList() {
                fetch
                    .post('/officialWebsiteMgr/resource/show_favor/hotShowFavor')
                    .then((res) => {
                        if (res.code == 0) {
                            this.coolHotList = res.data;
                        }
                    })
            },*/
            toDetail(id) {
                this.$router.push({
                    name: 'CoolDetail',
                    params: { id: id },
                })
            },
            goList(){
                window.close();
                /*this.$router.push({
                    name: "footprint",
                    params: {
                        type: this.detail.p1ClassId,
                        id: this.detail.pClassId
                    }
                })*/
            },
            like() {
                let sdata = {
                    type: 1,
                    pId: this.$route.params.id,
                    goodFlag: 1,
                }
                instance
                    .post('/officialWebsiteMgr/resource/like/upDown', sdata)
                    .then((res) => {
                        if (res.code == 0) {
                            this.detail.pageviews = this.detail.pageviews+1;
                        }
                    })
            },
        }

    };
</script>